.nested-table-style() {
  @light-grey-body: #fafafa;
  @light-grey-border: #e8e8e8;
  @light-grey-header: #ededed;

  .ant-table {
    border: 1px solid @light-grey-border;
    border-radius: 0;
  }

  .ant-table-container table>thead>tr:first-child th {
    background-color: @light-grey-header;
  }

  .ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 0;
  }

  .ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: 0;
  }

  .ant-table-thead {
    line-height: 10px;
  }

  .ant-table-row-level-0 {
    line-height: 6px;

    background-color: @light-grey-body;
  }
}