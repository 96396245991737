// Latest version of AntD v4 moved the default placement of the drawer from right to left.
.ant-drawer-close {
  padding-left: 0;
  margin-left: 0;
}

// Force drawer title text to accommodate for typography's descender
.ant-drawer-title {
  margin-bottom: -2px;
}
