.common-form {
  &-label {
    // Default spacing for Form label, patterned for Typography wrapper
    margin-bottom: 8px;
  }

  &-content {
    // For empty string, p collapses
    min-height: 34px;
    padding: 6px;

    // Default spacing between Form.Item, patterned for Typography
    // !important overrides antd element-level style
    margin-bottom: 24px !important;

    background: @grey;
    border-radius: 4px;
  }
}
