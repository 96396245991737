@import "style/theme.less";

.mb-n-2 {
  margin-bottom: -2px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-4 {
  margin-bottom: 4px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.super-admin {
  color: @pink !important;
}

.w-full {
  width: 100% !important;
}

.display-none {
  display: none;
}